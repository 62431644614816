import { Router } from "vue-router";
import * as rudderanalytics from "rudder-sdk-js";

export const RUDDERSTACK_EVENTS = {
  APP_EVENT_UPDATE_BANNER_REFRESH: "appEvent:updateBanner_refresh",
  APP_EVENT_MESSAGE_BANNER_CLICK: "appEvent:messageBanner_click",
  APP_EVENT_MESSAGE_BANNER_DISMISS: "appEvent:messageBanner_dismiss",
  APP_EVENT_PLATFORM_ALERT_DISMISS: "appEvent:platformAlert_dismiss", // click the x button
  APP_EVENT_PLATFORM_ALERT_OPEN: "appEvent:platformAlert_open", // click the banner in the nav
  APP_EVENT_PLATFORM_ALERT_VIEW_UPDATE: "appEvent:platformAlert_update", // banner visible again after dismiss event when version changes
  APP_EVENT_PLATFORM_ALERT_VIEW_NEW: "appEvent:platformAlert_firstView", // banner visible for the first time
  APP_EVENT_PLATFORM_ALERT_: "appEvent:platformAlert_click",
  APP_SEARCH_EXECUTE: "appEvent:search_execute",
  NOTES_ATTACHMENT_DELETE_ONE: "notes:attachments_deleteOne",
  NOTES_ATTACHMENT_DELETE_ALL: "notes:attachments_deleteAll",
  DASHBOARD_WIDGET_CREATE_NOTE: "dashboard:widget_createNote",
  DASHBOARD_WIDGET_DOWNLOAD_ATTACHMENT: "dashboard:widget_downloadAttachment",
  DASHBOARD_WIDGET_VIEW_ATTACHMENT: "dashboard:widget_viewAttachment",
  DASHBOARD_WIDGET_VIEW_NOTES: "dashboard:widget_viewNotes",
  DASHBOARD_WIDGET_SORT_ASSETS: "dashboard:widget_sortAssets",
  DASHBOARD_WIDGET_VISIT_VISIBILITY_DASHBOARD: "dashboard:widget_visitVisibilityDashboard",
  DASHBOARD_WIDGET_VISIT_ASSET_DETAILS: "dashboard:widget_visitAssetDetails",
  DASHBOARD_WIDGET_SELECT_CUSTOMER: "dashboard:widget_selectCustomer",
  DASHBOARD_WIDGET_SEARCH_CUSTOMER: "dashboard:widget_searchCustomer",
  DASHBOARD_WIDGET_CHANGE_ZIP_CODE: "dashboard:widget_changeZipCode",

  VISIBILITY_ASSET_BULK_SEARCH: "visibility:dashboardAssets_bulkSearch",
  VISIBILITY_ASSET_FILTER: "visibility:dashboardAssets_filter",
  VISIBILITY_ASSET_SORT: "visibility:dashboardAssets_sort",
  VISIBILITY_ASSET_DETAILS_COMPLETE_TRIP: "visibility:assetDetails_completeTrip",
  VISIBILITY_ASSET_DETAILS_DOWNLOAD_CSV: "visibility:assetDetails_downloadCsv",
  VISIBILITY_ASSET_DETAILS_OPEN_HISTORY: "visibility:assetDetails_openHistory",
  VISIBILITY_ASSET_DETAILS_CLOSE_HISTORY: "visibility:assetDetails_closeHistory",
  VISIBILITY_ASSET_DETAILS_FILTER_HISTORY: "visibility:assetDetails_filterHistory",
  VISIBILITY_ASSET_HISTORY_OPEN_TRIP: "visibility:assetHistory_openTrip",
  VISIBILITY_ASSET_HISTORY_FIRST_PAGE: "visibility:assetHistory_firstPage",
  VISIBILITY_ASSET_HISTORY_NEXT_PAGE: "visibility:assetHistory_nextPage",
  VISIBILITY_ASSET_HISTORY_PAGE_NUMBER: "visibility:assetHistory_pageNumber",
  VISIBILITY_ASSET_HISTORY_PREV_PAGE: "visibility:assetHistory_prevPage",
  VISIBILITY_ASSET_HISTORY_LAST_PAGE: "visibility:assetHistory_lastPage",
  VISIBILITY_ASSET_DETAILS_OPEN_WAYBILL_DETAILS: "visibility:assetDetails_openWaybillDetails",
  VISIBILITY_ASSET_DETAILS_CLOSE_WAYBILL_DETAILS: "visibility:assetDetails_closeWaybillDetails",
  VISIBILITY_ASSET_DETAILS_VIEW_ACTIVITY_FEED_OR_NOTES: "visibility:assetDetails_viewActivityFeedOrNotes",
  VISIBILITY_ASSET_DETAILS_CHANGE_MAP_LAYER: "visibility:assetDetails_changeMapLayer",
  VISIBILITY_ASSET_DETAILS_SHOW_RAIL_LAYER: "visibility:assetDetails_showRailLayer",
  VISIBILITY_ASSET_DETAILS_OPEN_RAIL_LAYERS: "visibility:assetDetails_openRailLayers",
  VISIBILITY_ASSET_WAYBILL_DETAILS_START_EDIT: "visibility:assetWaybillDetails_startEdit",
  VISIBILITY_ASSET_WAYBILL_DETAILS_SAVE_EDIT: "visibility:assetWaybillDetails_saveEdit",
  VISIBILITY_ASSET_WAYBILL_DETAILS_CANCEL_EDIT: "visibility:assetWaybillDetails_cancelEdit",
  VISIBILITY_ASSET_DASHBOARD_DOWNLOAD_FILE: "visibility:dashboardAssets_downloadCsv",
  VISIBILITY_ASSET_DETAILS_CREATE_NOTE: "visibility:assetDetails_createNote",
  VISIBILITY_ASSET_DETAILS_DOWNLOAD_ATTACHMENT: "visibility:assetDetails_downloadAttachment",
  VISIBILITY_ASSET_DETAILS_VIEW_ATTACHMENT: "visibility:assetDetails_viewAttachment",
  TELEMATICS_WATCHTOWER_OPEN_TOOLBOX: "telematics:watchtower_openToolbox",
  TELEMATICS_WATCHTOWER_FIRST_PAGE: "telematics:watchtower_firstPage",
  TELEMATICS_WATCHTOWER_NEXT_PAGE: "telematics:watchtower_nextPage",
  TELEMATICS_WATCHTOWER_PAGE_NUMBER: "telematics:watchtower_pageNumber",
  TELEMATICS_WATCHTOWER_PREV_PAGE: "telematics:watchtower_prevPage",
  TELEMATICS_WATCHTOWER_LAST_PAGE: "telematics:watchtower_lastPage",
  TELEMATICS_WATCHTOWER_SEARCH: "telematics:watchtower_search",
  TELEMATICS_WATCHTOWER_BULK_SEARCH: "telematics:watchtower_bulkSearch",
  TELEMATICS_WATCHTOWER_FILTER: "telematics:watchtower_filter",
  TELEMATICS_WATCHTOWER_OPEN_HISTORY: "telematics:watchtower_openHistory",
  TELEMATICS_WATCHTOWER_DOWNLOAD_HISTORY: "telematics:watchtower_downloadHistory",
  TELEMATICS_WATCHTOWER_BATCH_COMMAND_SENT: "telematics:watchtower_batchCommandSent",
  TELEMATICS_WATCHTOWER_BULK_COMMAND_SENT: "telematics:watchtower_bulkCommandSent",
  TELEMATICS_WATCHTOWER_ENABLE_BULK_SELECTION: "telematics:watchtower_bulkSelectStart",
  TELEMATICS_WATCHTOWER_CANCEL_BULK_SELECTION: "telematics:watchtower_bulkSelectCancel",
  TELEMATICS_WATCHTOWER_BULK_SELECT_ASSETS: "telematics:watchtower_bulkSelectAssets",
  TELEMATICS_WATCHTOWER_OPEN_SENT_COMMANDS_HISTORY: "telematics:watchtower_openSentCommandHistory",
  TELEMATICS_WATCHTOWER_DOWNLOAD_COMMAND_HISTORY: "telematics:watchtower_downloadCommandHistory",
  ANALYTICS_NETWORK_MAP_GENERATE: "analytics:networkMaps_generate",
  ANALYTICS_PIPELINE_GENERATE: "analytics:pipelineReport_generate",
  ANALYTICS_PIPELINE_DOWNLOAD_CSV: "analytics:pipelineReport_downloadCsv",
  ANALYTICS_PIPELINE_CHANGE_TABLE: "analytics:pipelineReport_changeTable",
  ANALYTICS_PIPELINE_CHANGE_REPORT_VIEW: "analytics:pipelineReport_changeReportView",
  ANALYTICS_PIPELINE_FILTER: "analytics:pipelineReport_filter",
  ANALYTICS_FORECAST_GENERATE: "analytics:forecasting_generate",
  ANALYTICS_FORECAST_GENERATE_EQUIPMENT_MODAL: "analytics:forecastingEquipmentModal_generate",
  ANALYTICS_FORECAST_DOWNLOAD_CSV: "analytics:forecasting_downloadCsv",
  ANALYTICS_ADVANCED_ANALYTICS_GENERATE_404: "analytics:advancedAnalytics_generate404",
  ANALYTICS_ADVANCED_ANALYTICS_FIRST_INTERACTIVE: "analytics:advancedAnalytics_firstInteractive",
  ANALYTICS_ADVANCED_ANALYTICS_TAB_SWITCHED: "analytics:advancedAnalytics_switchTabs",
  ANALYTICS_ADVANCED_ANALYTICS_CHANGE_FILTER: "analytics:advancedAnalytics_changeFilter",
  ANALYTICS_ADVANCED_ANALYTICS_CHANGE_PARAMETER: "analytics:advancedAnalytics_changeParameter",
  ANALYTICS_ADVANCED_ANALYTICS_DOWNLOAD_CSV: "analytics:advancedAnalytics_downloadCsv",
  ANALYTICS_ADVANCED_ANALYTICS_DOWNLOAD_PDF: "analytics:advancedAnalytics_downloadPdf",
  ETA_DETAILED_ETA_VIEW: "eta:detailedEta_view",
  EQUIPMENT_LOCOMOTIVES_LOAD: "equipment:locomotives_load",
  EQUIPMENT_LOCOMOTIVE_DETAILS_LOAD: "equipment:locomotiveDetail_load",
  EQUIPMENT_LOCOMOTIVE_DETAILS_CSV: "equipment:locomotiveDetail_downloadCsv",
  EQUIPMENT_FLEET_MANAGEMENT_SEARCH_EQUIPMENT: "equipment:fleetManagement_searchEquipment",
  EQUIPMENT_FLEET_MANAGEMENT_LOAD_EQUIPMENT: "equipment:fleetManagement_loadEquipment",
  EQUIPMENT_FLEET_MANAGEMENT_LOAD_EQUIPMENT_CYCLES: "equipment:fleetManagementEquipment_loadEquipmentCycles",
  EQUIPMENT_FLEET_MANAGEMENT_LOAD_EQUIPMENT_EVENTS: "equipment:fleetManagement_loadEvents",
  EQUIPMENT_FLEET_MANAGEMENT_DOWNLOAD_EVENTS: "equipment:fleetManagement_downloadCsv",
  REPORT_BUILDER_TEMPLATE_START: "reportBuilder:template_start",
  REPORT_BUILDER_TEMPLATE_CREATE: "reportBuilder:template_create",
  REPORT_BUILDER_TEMPLATE_UPDATE: "reportBuilder:template_update",
  REPORT_BUILDER_TEMPLATE_COPY: "reportBuilder:template_copy",
  REPORT_BUILDER_TEMPLATE_DELETE: "reportBuilder:template_delete",
  REPORT_BUILDER_TEMPLATE_SHOW_ALL_TEMPLATES: "reportBuilder:template_showAllTemplates",
  REPORT_BUILDER_TEMPLATE_SEARCH: "reportBuilder:template_search",
  REPORT_BUILDER_REPORT_SHOW_ALL_REPORTS: "reportBuilder:report_showAllReports",
  REPORT_BUILDER_REPORT_SEARCH: "reportBuilder:report_search",
  REPORT_BUILDER_REPORT_GENERATE: "reportBuilder:report_generate",
  REPORT_BUILDER_REPORT_START: "reportBuilder:report_start",
  REPORT_BUILDER_REPORT_CREATE: "reportBuilder:report_create",
  REPORT_BUILDER_REPORT_UPDATE: "reportBuilder:report_update",
  REPORT_BUILDER_REPORT_COPY: "reportBuilder:report_copy",
  REPORT_BUILDER_REPORT_DELETE: "reportBuilder:report_delete",
  REPORT_BUILDER_REPORT_PREVIEW: "reportBuilder:report_preview",
  REPORT_BUILDER_REPORT_SHARE: "reportBuilder:report_share",
  REPORT_BUILDER_REPORT_SAVE_SCHEDULE: "reportBuilder:report_saveSchedule",
  REPORT_BUILDER_REPORT_DELETE_SCHEDULE: "reportBuilder:report_deleteSchedule",
  REPORT_BUILDER_REPORT_DOWNLOAD_CSV: "reportBuilder:report_downloadCsv",
  REPORT_BUILDER_REPORT_DOWNLOAD_XLSX: "reportBuilder:report_downloadXlsx",
  REPORT_BUILDER_REPORT_DOWNLOAD_PDF: "reportBuilder:report_downloadPdf",
  PRICING_QUOTES_SEARCH: "pricing:quotes_search", // search for quote in list
  PRICING_QUOTES_SORT: "pricing:quotes_sort", // sorting quote list
  PRICING_QUOTES_FILTER: "pricing:quotes_filter", // sorting quote list
  PRICING_QUOTE_CREATE_QUOTE: "pricing:quote_createQuote", // clicking the submit new request button in modal
  PRICING_QUOTE_EDIT_DETAILS: "pricing:quote_editDetails", // saving changes to quote ribbon after creation
  PRICING_QUOTE_SAVE_QUOTE: "pricing:quote_saveQuote", // clicking the disk icon in the quote builder page
  PRICING_QUOTE_REVIEW_QUOTE: "pricing:quote_reviewQuote", // clicking the blue review quote button
  PRICING_QUOTE_COPY: "pricing:quote_copy", // clicking dot menu and copy
  PRICING_QUOTE_DELETE_QUOTE: "pricing:quote_deleteQuote",
  PRICING_QUOTE_COPY_LINK: "pricing:quote_copyLink",
  PRICING_QUOTE_CHANGE_ACTIVITY_VIEW: "pricing:quote_changeActivityView",
  PRICING_QUOTE_CREATE_INTERNAL_NOTE: "pricing:quote_createInternalNote",
  PRICING_QUOTE_SEND_QUOTE: "pricing:quote_sendQuote", // clicking green buton in the review quote modal
  PRICING_QUOTE_SHIPPER_CREATE_QUOTE: "pricing:quote_shipperCreateQuote", // clicking the submit new request button
  PRICING_QUOTE_SHIPPER_ACCEPT: "pricing:quote_shipperSendAccept", // clicking accept button on shipper quote review page
  PRICING_QUOTE_SHIPPER_START_COUNTER: "pricing:quote_shipperStartCounter", // clicking counter button on shipper quote review page
  PRICING_QUOTE_SHIPPER_START_REJECT: "pricing:quote_shipperStartReject", // clicking reject button on shipper quote review page
  PRICING_QUOTE_SHIPPER_SEND_COUNTER: "pricing:quote_shipperSendCounter", // clicking counter button on shipper counter modal
  PRICING_QUOTE_SHIPPER_SEND_REJECTION: "pricing:quote_shipperSendRejection", // clicking counter button on shipper counter modal
  TERMINAL_ASSETS_FILTER: "terminal:assets_filter",
  TERMINAL_ASSETS_SEARCH: "terminal:assets_search",
  TERMINAL_ASSET_OPEN_DETAILS: "terminal:asset_openDetails",
  TERMINAL_ASSET_CREATE_NOTE: "terminal:asset_createNote",
  TERMINAL_ASSET_DOWNLOAD_ATTACHMENT: "terminal:asset_downloadAttachment",
  TERMINAL_ASSET_VIEW_ATTACHMENT: "terminal:asset_viewAttachment",
  TERMINAL_ASSET_START_TIME: "terminal:asset_startTime",
  TERMINAL_ASSET_END_TIME: "terminal:asset_endTime",
  TERMINAL_ASSET_REMOVE_TIME: "terminal:asset_removeTime",
  USER_PROFILE_EDIT: "user:user_profile_edit",

  // waybilling module
  WAYBILLING_BOOKING_REQUEST_LIST_SEARCH: "waybilling:bookingRequestList_search",
  WAYBILLING_BOOKING_REQUEST_LIST_SORT: "waybilling:bookingRequestList_sort",
  WAYBILLING_BOOKING_REQUEST_LIST_VIEW: "waybilling:bookingRequestList_view",
  WAYBILLING_BOOKING_REQUEST_LIST_EDIT: "waybilling:bookingRequestList_edit",
  WAYBILLING_BOOKING_REQUEST_VIEW_DONE: "waybilling:bookingRequestView_done",
  WAYBILLING_BOOKING_REQUEST_EDIT_RESUBMIT: "waybilling:bookingRequestEdit_resubmit",
  WAYBILLING_BOOKING_REQUEST_EDIT_CANCEL: "waybilling:bookingRequestEdit_cancel",
  WAYBILLING_PATTERN_LIST_CREATE_PATTERN: "waybilling:patternList_createPattern",
  WAYBILLING_PATTERN_LIST_EDIT_PATTERN: "waybilling:patternList_editPattern",
  WAYBILLING_PATTERN_LIST_DUPLICATE_PATTERN: "waybilling:patternList_duplicatePattern",
  WAYBILLING_PATTERN_LIST_DELETE_PATTERN: "waybilling:patternList_deletePattern",
  WAYBILLING_PATTERN_LIST_BOOK_TENDER: "waybilling:patternList_bookTender",
  WAYBILLING_PATTERN_LIST_TOGGLE_FILTER: "waybilling:patternList_toggleFilter",
  WAYBILLING_NEW_PATTERN_CREATE: "waybilling:newPattern_create",
  WAYBILLING_NEW_PATTERN_CANCEL: "waybilling:newPattern_cancel",
  WAYBILLING_EDIT_PATTERN_SAVE: "waybilling:editPattern_save",
  WAYBILLING_EDIT_PATTERN_CANCEL: "waybilling:editPattern_cancel",
  WAYBILLING_CREATE_TENDER_SUBMIT: "waybilling:createTender_submit",
  WAYBILLING_CREATE_TENDER_CANCEL: "waybilling:createTender_cancel",

  // customer support module
  CUSTOMER_SUPPORT_TICKETS_FILTER: "customerSupport:tickets_filter", // filter tickets
  CUSTOMER_SUPPORT_TICKETS_RESET_FILTER: "customerSupport:tickets_resetFilter", // reset the filters
  CUSTOMER_SUPPORT_TICKETS_REFRESH: "customerSupport:tickets_refresh", // refresh the ticket list
  CUSTOMER_SUPPORT_TICKETS_SEARCH_ASSETS: "customerSupport:tickets_searchAssets", // search assets in the filter menu
  CUSTOMER_SUPPORT_TICKET_START_CREATE: "customerSupport:ticket_startCreate", // click the new ticket button
  CUSTOMER_SUPPORT_TICKET_CREATE: "customerSupport:ticket_create", // click the submit button in the new ticket form
  CUSTOMER_SUPPORT_TICKET_CANCEL_CREATE: "customerSupport:ticket_cancelCreate", // click the cancel button in the new ticket form
  CUSTOMER_SUPPORT_TICKET_VIEW: "customerSupport:ticket_view", // click on a ticket in the list
  CUSTOMER_SUPPORT_TICKET_CREATE_NOTE: "customerSupport:ticket_createNote", // create a note in the ticket details view, will have an attachment property if there is an attachment
  CUSTOMER_SUPPORT_TICKET_DOWNLOAD_ATTACHMENT: "customerSupport:ticket_downloadAttachment", // download an attachment in the ticket details attachement modal
  CUSTOMER_SUPPORT_TICKET_VIEW_ATTACHMENT: "customerSupport:ticket_viewAttachment", // view an attachment in the ticket details attachement modal
  CUSTOMER_SUPPORT_TICKET_START_ASSIGN: "customerSupport:ticket_startAssign", // click the assign button in the ticket details or from the list
  CUSTOMER_SUPPORT_TICKET_ASSIGN_OTHER: "customerSupport:ticket_assignOther", // search for and assign a ticket to another user, can send diff if reassigning in the payload
  CUSTOMER_SUPPORT_TICKET_ASSIGN_SELF: "customerSupport:ticket_assignSelf", // assign a ticket to yourself, can send diff if reassigning to self from other user in the payload
  CUSTOMER_SUPPORT_TICKET_CLOSE: "customerSupport:ticket_close", // click the close ticket button in the modal, no tracking modal open
  CUSTOMER_SUPPORT_TICKET_CANCEL_CLOSE: "customerSupport:ticket_cancelClose", // click the cancel button in the close ticket modal
  CUSTOMER_SUPPORT_TICKET_START_EDIT: "customerSupport:ticket_startEdit", // click the edit button in the ticket details
  CUSTOMER_SUPPORT_TICKET_EDIT: "customerSupport:ticket_edit", // click the save button in the ticket edit form
  CUSTOMER_SUPPORT_TICKET_CANCEL_EDIT: "customerSupport:ticket_cancelEdit", // click the cancel button in the ticket edit form
  CUSTOMER_SUPPORT_TICKET_OPEN_ASSET_DETAILS: "customerSupport:ticket_openAssetDetails", // click on an asset in the ticket details to go to the asset details page

  // end user platform events
  ADMIN_CUSTOMER_SEARCH: "adminTools:customers_search", // begin admin platform events
  ADMIN_CUSTOMER_LOAD: "adminTools:customerDetails_load",
  ADMIN_CUSTOMER_LOAD_TAB: "adminTools:customerDetails_changeTab:",
  ADMIN_CUSTOMER_CREATE: "adminTools:customer_create",
  ADMIN_CUSTOMER_EDIT: "adminTools:customerDetails_edit",
  ADMIN_CUSTOMER_DISABLE: "adminTools:customer_disable",
  ADMIN_CUSTOMER_ACTIVATE: "adminTools:customer_enable",
  ADMIN_CUSTOMER_USER_DELETE: "adminTools:customer_deleteUser",
  ADMIN_CUSTOMER_USER_ADD: "adminTools:customer_addUser",
  ADMIN_CUSTOMER_PERMISSION_REMOVE: "adminTools:customer_removePermission",
  ADMIN_CUSTOMER_PERMISSION_ADD: "adminTools:customer_addPermission",
  ADMIN_CUSTOMER_EQUIPMENT_SEARCH: "adminTools:customerEquipment_search",
  ADMIN_CUSTOMER_PARTY_SEARCH: "adminTools:customerParty_search",
  ADMIN_CUSTOMER_REPORTING_GROUP_LOAD: "adminTools:reportingGroup_load",
  ADMIN_CUSTOMER_REPORTING_GROUP_SEARCH: "adminTools:reportingGroup_search",
  ADMIN_CUSTOMER_REPORTING_GROUP_UPDATE: "adminTools:reportingGroupName_update",
  ADMIN_CUSTOMER_REPORTING_GROUP_PARTY_SEARCH: "adminTools:reportingGroupParty_search",
  ADMIN_CUSTOMER_REPORTING_GROUP_EQUIPMENT_SEARCH: "adminTools:reportingGroupEquipment_search",
  ADMIN_CUSTOMER_REPORTING_GROUP_PERMISSION_REMOVE: "adminTools:reportingGroupPermission_remove",
  ADMIN_CUSTOMER_REPORTING_GROUP_PERMISSION_ADD: "adminTools:reportingGroupPermission_create",
  ADMIN_USER_SEARCH: "adminTools:users_search",
  ADMIN_USER_CREATE: "adminTools:user_create",
  ADMIN_USER_EDIT: "adminTools:userDetails_edit",
  ADMIN_USER_DISABLE: "adminTools:user_disable",
  ADMIN_USER_ACTIVATE: "adminTools:user_activate",
  ADMIN_USER_PASSWORD_RESET: "adminTools:userPassword_reset",
  ADMIN_USER_CUSTOMER_ADD: "adminTools:user_addCustomer",
  ADMIN_USER_CUSTOMER_REMOVE: "adminTools:user_removeCustomer",
} as const;

export type RudderstackEvent = (typeof RUDDERSTACK_EVENTS)[keyof typeof RUDDERSTACK_EVENTS];

export default class RudderStack {
  public init(): void {
    // @ts-ignore
    if (!import.meta.env.VITE_LOCAL_APP) {
      if (!import.meta.env.VITE_RUDDERSTACK_WRITE_KEY) {
        console.error("Could not initialize rudderstack: WRITE_KEY not provided.");
        return undefined;
      }
      // @ts-ignore
      if (!import.meta.env.VITE_RUDDERSTACK_DATA_PLANE_URL) {
        console.error("Could not initialize rudderstack: DATA_PLANE_URL not provided.");
        return undefined;
      }

      rudderanalytics.ready(() => console.log("Rudder ready."));

      // @ts-ignore
      rudderanalytics.load(
        import.meta.env.VITE_RUDDERSTACK_WRITE_KEY,
        import.meta.env.VITE_RUDDERSTACK_DATA_PLANE_URL,
        {
          configUrl: "https://telegraph.io/",
        },
      );
    }
  }

  public registerRouter(router: Router): void {
    if (!import.meta.env.VITE_LOCAL_APP) {
      router.beforeEach((to, from) => {
        // if (to.path === from.path) return;
        rudderanalytics.page(to.name as string, `${to.name as string} viewed`, {
          path: to.path,
          search: JSON.stringify(to.query),
          params: JSON.stringify(to.params),
          referrer: from.path,
        });
      });
    }
  }

  public identifyUser(
    userId: string,
    firstName: string,
    lastName: string,
    isAdmin: boolean,
    companyId: string,
    appVersion: string,
    userRoleId: number,
    email?: string,
  ): void {
    if (!import.meta.env.VITE_LOCAL_APP) {
      const idData: { [key: string]: any } = {
        first_name: firstName,
        last_name: lastName,
        is_admin: isAdmin,
        company: companyId,
        app_version: appVersion,
        user_role_id: userRoleId,
        user_id: userId,
      };
      if (email) {
        idData.email = email;
      }
      rudderanalytics.identify(userId, idData);
    }
  }

  public logEvent(event: RudderstackEvent, properties: any): void {
    if (!import.meta.env.VITE_LOCAL_APP) {
      rudderanalytics.track(event, properties);
    }
  }

  public resetIdentifier() {
    rudderanalytics.reset(true);
  }
}
