/**
 * BAPI_COMMANDS
 * Const of valid commands that a consumer can issue to the BAPI client.
 * Use the keys of BAPI_COMMANDS instead of the string literal values for better type
 * safety and easier refactoring if something changes.
 *
 * @remarks
 * Adding a handler (feature) to BAPI Client requires adding a new
 * BAPI_COMMAND, so that other mapped types can reference the same type.
 */
export const BAPI_COMMANDS = {
  GET_REPORT_TEMPLATES: "getReportTemplates",
  GET_REPORT_TEMPLATE: "getReportTemplate",
  DELETE_REPORT_TEMPLATE: "deleteReportTemplate",
  CREATE_REPORT_TEMPLATE: "createReportTemplate",
  UPDATE_REPORT_TEMPLATE: "updateReportTemplate",
  GET_REPORT_TEMPLATE_FIELDS: "getReportTemplateFields",
  ADMIN_GET_CUSTOMER: "adminGetCustomer",
  ADMIN_GET_CUSTOMERS: "adminGetCustomers",
  ADMIN_CREATE_CUSTOMER: "adminCreateCustomer",
  ADMIN_UPDATE_CUSTOMER: "adminUpdateCustomer",
  ADMIN_DELETE_CUSTOMER: "adminDeleteCustomer",
  ADMIN_CUSTOMER_EQUIPMENT_ADD: "adminCustomerEquipmentAdd",
  ADMIN_CUSTOMER_EQUIPMENT_DELETE: "adminCustomerEquipmentDelete",
  ADMIN_LIST_PERMISSIONS: "adminListPermissions",
  ADMIN_GET_CUSTOMER_PERMISSIONS: "adminGetCustomerPermissions",
  ADMIN_UPDATE_CUSTOMER_PERMISSIONS: "adminUpdateCustomerPermissions",
  USER_GET_ME: "userGetMe",
  USER_LOGIN: "userLogin",
  USER_LOGOUT: "userLogout",
  USER_GET_PROFILE_PICTURE_UPLOAD_URL: "userGetProfilePictureUpload",
  USER_UPLOAD_PROFILE_PICTURE: "userUploadProfilePicture",
  USER_GET_PASSWORD_RESET_EMAIL: "userGetPasswordResetEmail",
  USER_RESET_PASSWORD: "userResetPassword",
  USER_PERMISSIONS: "userPermissions",
  GET_VISIBILITY_ASSETS: "getVisibilityAssets",
  GET_VISIBILITY_ASSETS_DOWNLOADABLE: "getVisibilityAssetsDownloadable",
  WAYBILL_GET_ACTIVITY_FEED: "waybillGetActivityFeed",
  WAYBILL_GET_WAYBILL_DETAILS: "waybillGetWaybillDetails",
  GET_ASSET_HISTORY: "getAssetHistory",
  // CS module
  CUSTOMER_SUPPORT_GET_TICKET: "getTicket",
  CUSTOMER_SUPPORT_GET_TICKETS: "getTickets",
  CUSTOMER_SUPPORT_CREATE_TICKET: "createTicket",
  CUSTOMER_SUPPORT_DELETE_TICKET: "deleteTicket",
  CUSTOMER_SUPPORT_UPDATE_TICKET: "updateTicket",
  CUSTOMER_SUPPORT_GET_TICKET_NOTES: "getTicketNotes",
  CUSTOMER_SUPPORT_CREATE_TICKET_NOTE: "createTicketNote",
  CUSTOMER_SUPPORT_GET_TICKET_NOTE_SIGNED_UPLOAD_URL: "getTicketNoteSignedUploadURL",
  // External Invoicing commands
  EXTERNAL_INVOICING_CREATE_INVOICE: "createExternalInvoice",
  // common commands
  GET_STCCS: "getStccs",
  GET_DESTINATIONS: "getDestinations",
  GET_ORIGINS: "getOrigins",
  S3_UPLOAD_FILE: "s3UploadFile",
  GET_ALL_LOCATIONS: "getAllLocations",
  GET_REPORTING_GROUPS: "getReportingGroups",
  // "updateTemplate",
  // "deleteTemplate",
  AUTOCOMPLETE_USERS: "autocompleteUsers",
  AUTOCOMPLETE_ASSETS: "autocompleteAssets",
  AUTOCOMPLETE_SCACS: "autocompleteScacs",
  INTELEDESK_SUBMIT: "submitInteledeskForm",
  REPORT_BUILDER_GET_REPORT_LIST: "getReportList",
  REPORT_BUILDER_GET_REPORT_DETAILS: "getReportDetails",
  REPORT_BUILDER_DOWNLOAD_REPORT: "reportBuilderDownloadReport",
  REPORT_BUILDER_DELETE_REPORT: "reportBuilderDeleteReport",
  REPORT_BUILDER_GET_REPORT_DATA: "reportBuilderGetReportData",
  REPORT_BUILDER_CREATE_REPORT: "reportBuilderCreateReport",
  REPORT_BUILDER_GET_REPORT_SCHEDULE: "reportBuilderGetReportSchedule",
  REPORT_BUILDER_SHARE_REPORT: "reportBuilderShareReport",
  REPORT_BUILDER_SAVE_REPORT_SCHEDULE: "reportBuilderSaveReportSchedule",
  REPORT_BUILDER_DELETE_REPORT_SCHEDULE: "reportBuilderDeleteReportSchedule",
  REPORT_BUILDER_UPDATE_REPORT: "reportBuilderUpdateReport",
  REPORT_BUILDER_PREVIEW_REPORT: "reportBuilderPreviewReport",
  // invoicing
  INVOICING_GET_PAYEE: "getInvoicingPayee",
  INVOICING_GET_COMPANY: "getInvoicingCompany",
  INVOICING_UPDATE_PAYEE: "updateInvoicingPayee",
  INVOICING_UPDATE_COMPANY: "updateInvoicingCompany",
  EXTERNAL_INVOICING_GET_ALL_INVOICES: "getAllExernalInvoices",
  EXTERNAL_INVOICING_GET_PAYEE: "getInvoicingPayee",
  EXTERNAL_INVOICING_GET_COMPANY: "getInvoicingCompany",
  EXTERNAL_INVOICING_UPDATE_PAYEE: "updateInvoicingPayee",
  EXTERNAL_INVOICING_UPDATE_COMPANY: "updateInvoicingCompany",
  EXTERNAL_INVOICING_CREATE_COMPANY: "createInvoicingCompany",
} as const;

/**
 * BAPICommand
 * Shorthand type for the literal values of BAPI_COMMANDS. Used for composing
 * mapped types and generics that take a BAPICommand as a type or function argument.
 */
export type BAPICommand = (typeof BAPI_COMMANDS)[keyof typeof BAPI_COMMANDS];
