<script setup lang="ts">
import { BAPITicketAsset } from "@/bapi-client/types/customer-support";
import AssetFieldsItem from "@/components/customerSupport/AssetFieldsItem.vue";

interface Props {
  customerId: string;
  labelClasses: string;
  assets?: BAPITicketAsset[];
  ignore?: boolean;
  showAddButton?: boolean;
}

withDefaults(defineProps<Props>(), {
  ignore: false,
  showAddButton: true,
  assets: () => [],
});
</script>

<template>
  <FormKit
    :ignore
    name="ticket_assets"
    type="repeater"
    :up-control="false"
    :down-control="false"
    :classes="{
      item: 'border-0 group',
      content: '!p-0 !flex-row gap-3 w-full',
      controls: 'border-0 !py-1',
      insert: 'group-[:not(:first-of-type)]:hidden',
      remove: 'group-first:hidden',
      outer: 'grow',
    }"
    :add-button="false"
    add-icon="faPlusSquare"
    remove-icon="faMinusSquare"
    :insert-control="true"
    :remove-control="true"
    :value="assets"
  >
    <AssetFieldsItem :customer-id="customerId" :label-classes="labelClasses" />
    <template v-if="showAddButton" #addIcon>
      <i class="fa-solid fa-plus-square text-xl text-blue-500" />
    </template>
    <template #removeIcon>
      <i class="fa-solid fa-minus-square text-xl text-blue-500" />
    </template>
  </FormKit>
</template>
